<template>
    <v-dialog v-model="modalOpen" max-width="500px">
        <v-card>
            <v-card-title>
                <span class="headline">BAN SINGLE HWID</span>
            </v-card-title>
            <v-card-text>
                <v-text-field
                    v-model="hwid"
                    label="HWID"
                    single-line
                    hide-details
                    class="mb-2"
                    readonly
                />
                <v-textarea
                    v-model="reason"
                    label="Reason"
                    hint="Reason for banning this HWID"
                    rows="3"
                    clearable
                />
            </v-card-text>
            <v-card-actions>
                <template v-if="error_message !== ''">
                    <v-alert type="error" dismissible>
                        {{ error_message }}
                    </v-alert>
                </template>
                <v-btn color="green darken-1" @click="doBan" :disabled="check_disabled" :loading="loading">Ban HWID</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" @click="modalOpen = false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {fetchData} from "../helpers";
import { mapActions } from 'vuex'
export default {
    data() {
        return {
            modalOpen: false,
            hwid: '',
            reason: '',
            error_message: '',
            loading: false,
        };
    },
    computed: {
        check_disabled(){
            if (this.loading) {
                return true;
            }
            if (this.reason === '' || this.hwid === '') {
                return true;
            }
            return false;
        }
    },
    methods: {
        ...mapActions('alert', ['success', 'error']),
        hex2bin(hex) {
            let str = '';
            for (let i = 0; i < hex.length; i += 2) {
                str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
            }
            return str;
        },
        openModal(hwid) {
            this.loading = false;
            this.error_message = '';
            this.reason = '';
            this.hwid = this.hex2bin(hwid);
            this.modalOpen = true;
        },
        doBan(){
            this.loading = true;
            fetchData(`${this.$API}/api/Blacklist/add`, {bl_hwid: this.hwid, bl_reason: this.reason})
            .then((json)=>{
                if(json.success){
                    this.error_message = '';
                    this.reason = '';
                    this.hwid = '';
                    this.modalOpen = false;
                    this.success(json.message);
                }else{
                    this.error_message = json.message;
                }
            }).catch(e=>{
                this.error_message = e.message;
            }).finally(()=>this.loading = false);

        }
    },
};
</script>
