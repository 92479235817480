<template>
    <v-dialog v-model="modalOpen" max-width="500px">
        <v-card>
            <v-card-title>
                <span class="headline">List IP IPs</span>
            </v-card-title>
            <v-card-text>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    class="mb-2"
                />
                <v-data-table :headers="headers" :items="bannedIPs" :search="search" hide-default-footer>
                    <template v-slot:item.country="{ item }">
                        <div>
                            <v-chip  small class="mb-1 mx-1">
                                <gb-flag
                                    :code="item.country.toString().toLowerCase()"
                                    size="mini"
                                />
                                <span class="ml-2">{{ item.country }}</span>
                            </v-chip>
                        </div>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-checkbox
                            :value="selected_ips.includes(item.ip)"
                            @change="doToggle(item.ip)"
                        />
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <template v-if="error_message !== ''">
                    <v-alert type="error" dismissible>
                        {{ error_message }}
                    </v-alert>
                </template>
                <v-btn color="green darken-1" @click="doBan" :disabled="check_disabled" :loading="loading">Ban ({{ selected_ips.length }}) IP(s)</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" @click="modalOpen = false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {fetchData} from "../helpers";
import { mapActions } from 'vuex'
export default {
    data() {
        return {
            modalOpen: false,
            headers: [
                { text: 'IP Address', value: 'ip', sortable: false },
                { text: 'Country', value: 'country', sortable: false },
                { text: 'Pick to Ban', value: 'actions', sortable: false },
            ],
            bannedIPs: [],
            selected_ips: [],
            search: '',
            error_message: '',
            loading: false,
        };
    },
    computed: {
        check_disabled(){
            if (this.loading) {
                return true;
            }

            if (this.selected_ips.length > 0) {
                return false;
            }
            return true;
        }
    },
    methods: {
        ...mapActions('alert', ['success', 'error']),
        doToggle(ip){
            if (this.selected_ips.includes(ip)) {
                this.selected_ips = this.selected_ips.filter((item) => item !== ip);
            } else {
                this.selected_ips.push(ip);
            }
        },
        openModal(list_ip) {
            this.loading = false;
            this.error_message = '';
            this.selected_ips = [];
            this.modalOpen = true;
            this.bannedIPs = list_ip.map((ip) => ({ ip: ip.address, country: ip.country }));
        },
        doBan(){
            this.loading = true;
            fetchData(`${this.$API}/api/block-ips`, {ips: this.selected_ips})
            .then((json)=>{
                if(json.success){
                    this.selected_ips = [];
                    this.error_message = '';
                    this.modalOpen = false;
                    this.success(json.message);
                }else{
                    this.error_message = json.message;
                }
            }).catch(e=>{
                this.error_message = e.message;
            }).finally(()=>this.loading = false);

        }
    },
};
</script>
