<template>
    <div style="max-height: 220px; overflow-y: auto;">
      <v-chip
        v-for="data in items"
        :key="data"
        small
        class="mb-1 mx-1"
        v-clipboard="flag ? data.split(':')[0]:data"
      >
        <template v-if="flag == false">
            {{ data }}
        </template>
        <template v-else>
            <span class="mx-2">{{ data.split(':')[0] }} ({{data.split(':')[1]}})</span>
            <gb-flag
                :code="data.split(':')[1].toString().toLowerCase()"
                size="mini"
            />
        </template>
      </v-chip>
    </div>
  </template>
  
<script>
import { clipboard } from 'vue-clipboards';
export default {
    props: {
        items: {
        type: Array,
        required: true
        },
        flag: false,
    },
    directives: { clipboard },
};
</script>