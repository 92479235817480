<template>
    <div>
        <v-textarea
            v-model="logs"
            class="mx-2"
            label="Logs"
            rows="3"
        />
        <v-btn
            color="success"
            dark
            small
            icon
            @click="save_data"
            v-if="canEmit"
            class="mx-1"
        >
            <v-icon
                light
                right
            >
                mdi-content-save-check
            </v-icon>
        </v-btn>
        <v-btn
            color="deep-orange darken-1"
            dark
            small
            icon
            @click="cancel"
            v-if="canEmit"
            class="mx-1"
        >
            <v-icon
                light
                right
            >
                mdi-cancel
            </v-icon>
        </v-btn>
    </div>
</template>
<script>
export default {
    props:{
        content:{
            type: String,
            default: '',
        }
    },
    data(){
        return {
            logs: '',
            canEmit: false,
        }
    },
    methods:{
        update_data(content){
            this.logs = content;
        },
        save_data(){
            this.$emit('onUpdate', this.logs);
        },
        cancel(){
            this.$emit('onUpdate', false);
        }
    },
    created() {
        if (this.$listeners.onUpdate) {
            this.canEmit = true;
        }
        this.logs = this.content;
    },
}
</script>