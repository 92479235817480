export function fetchData(url,data,method, upload= undefined)
{
    let DefaultRequest = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('Token'),
        },
    };
    if(typeof(data) !== "undefined")
    {
        DefaultRequest.method = "POST";
        DefaultRequest.body = JSON.stringify(data);
    }
    if(typeof(method) !== "undefined"){
        DefaultRequest.method = method;
    }
    if(upload && typeof(data) !== "undefined"){
        const formData = new FormData();
        formData.append('file', upload);
        formData.append('data', JSON.stringify(data));
        delete DefaultRequest.headers['Content-Type']
        DefaultRequest.method = "POST";
        DefaultRequest.body = formData;
    }
    return fetch(`${url}`,DefaultRequest).then((resp)=>{
        if (resp.status === 401) {
            return Promise.reject(401);
        }
        if(resp.status !== 200){
            return Promise.reject(resp.status);
        }
        const contentType = resp.headers.get("Content-Type");
        if (contentType.includes("application/json")) {
            return resp.json();
        } else if (contentType.includes("text/")) {
            return resp.text();
        } else {
            return resp.blob();
        }
    },(error)=>{
        return Promise.reject(error);
    });
}
export function downloadData(url,data,method)
{
    let DefaultRequest = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('Token'),
        },
    };
    if(typeof(data) !== "undefined")
    {
        DefaultRequest.method = "POST";
        DefaultRequest.body = JSON.stringify(data);
    }
    if(typeof(method) !== "undefined"){
        DefaultRequest.method = method;
    }
    return fetch(`${url}`,DefaultRequest).then((resp)=>{
        if (resp.status === 401) {
            return Promise.reject(401);
        }
        if(resp.status !== 200){
            return Promise.reject(resp.status);
        }
        return resp.blob();
    },(error)=>{
        return Promise.reject(error);
    });
}
export function getTextData(url,data,method)
{
    let DefaultRequest = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('Token'),
        },
    };
    if(typeof(data) !== "undefined")
    {
        DefaultRequest.method = "POST";
        DefaultRequest.body = JSON.stringify(data);
    }
    if(typeof(method) !== "undefined"){
        DefaultRequest.method = method;
    }
    return fetch(`${url}`,DefaultRequest).then((resp)=>{
        if (resp.status === 401) {
            return Promise.reject(401);
        }
        if(resp.status !== 200){
            return Promise.reject(resp.status);
        }
        return resp.text();
    },(error)=>{
        return Promise.reject(error);
    });
}