<template>
  <v-row>
    <v-col cols="6">
      <form @submit.prevent="submitForm">
        <v-card>
          <v-card-title>
              <h1>{{ title }}</h1>
          </v-card-title>
          <v-card-text>
            <div>
              <label for="appName">Version:</label>
              <v-text-field id="appName" v-model="appName" type="text" required />
            </div>
          </v-card-text>
          <v-card-text>
            <div>
              <label for="apk">APK:</label>
              <v-text-field id="apk" v-model="appAPK" type="text" required />
            </div>
          </v-card-text>
          <v-card-text>
            <div>
              <label for="obb">OBB:</label>
              <v-text-field id="obb" v-model="appOBB" type="text" required />
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn type="submit" color="success" :disabled="!appAPK || !appOBB || processing" :loading="processing">
              Build XAPK
              <v-icon right>
                mdi-package-variant-closed-check
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>  
    </v-col>
    <v-col cols="6">
      <v-card v-if="auto_status">
        <v-card-title>Status: {{ auto_completed ? 'Completed' : 'Building...' }}</v-card-title>
        <v-card-text class="status-message">
          <span class="typing-effect" v-html="auto_status_message" />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<style scoped>
.status-message {
  white-space: pre-wrap;
  font-family: monospace;
  line-height: 1.5;
  overflow: hidden;
}

.typing-effect {
  display: inline-block;
  overflow: hidden;
  border-right: 0.15em solid green;
  white-space: nowrap;
  margin: 0;
  letter-spacing: 0.15em;
  animation: 
    typing 3.5s steps(40, end),
    blink-caret 0.75s step-end infinite;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: green; }
}
</style>

<script>
import {http} from '../helpers/http';
import notice from '../helpers/notice';
export default {
  name: 'CreateXAPK',
  data() {
    return {
      title: 'Create XAPK file',
      appName: '',
      appAPK: '',
      appOBB: '',
      processing: false,
      auto_status: null,
      auto_completed: false,
      auto_status_message: '',
    }
  },
  methods: {
    fetchStatus(code){
      http.get(`/api/v1/apk/xapk/status?task=${code}`).then((res)=>{
          const json = res.data;
          if(json.message){
            this.auto_status_message = json.message;
            if(json.message.includes("<br />END")){
              clearInterval(this.auto_status);
              this.auto_completed = true;
            }
        }
      })
    },
    PickAPK(top, f){
      this.appAPK = `${top}/${f.file_name}`;
    },
    PickOBB(top, f){
      this.appOBB = `${top}/${f.file_name}`;
    },
    submitForm() {
      this.processing = true;
      http.get(`/api/v1/apk/xapk?xapk=${this.appName}&apk=${this.appAPK}&obb=${this.appOBB}`)
        .then(res => {
          const json = res.data;
          if(json.success){
            notice.success({message: json.message || "Building..."});
            if(json.data){
                this.auto_status = setInterval(()=>{
                  this.fetchStatus(json.data);
                }, 1000);
              }else{
                notice.error("Not found session key");
              }
          }else{
            notice.error({message: json.message || "Unknown Error"});
          }
        })
        .catch(err => {
          notice.error({message: err.error});
        })
        .finally(() => {
          this.processing = false;
        });
    }
  },
  beforeDestroy() {
    if(this.auto_status){
      this.auto_completed = false;
      clearInterval(this.auto_status);
    }
  },
}
</script>
