import ua from 'qiao-ua';
const get_image = (ua) => {
    let {browser, os} = ua;
    let browser_image = "";
    let os_image = "";
    switch (os.name) {
        case "macOS":
            os_image = "/images/mac_logo.svg";
        break;
        case "iOS":
            os_image = "/images/ios.svg";
        break;
        case "Android":
            os_image = "/images/android.svg";
        break;
        case "Windows":
            os_image = "/images/windows_logo.svg";
        break;
        case "Linux":
            os_image = "/images/linux.svg";
        break;
    };
    switch(browser.name){
        case "Chrome":
            browser_image = "/images/chrome.png";
        break;
        case "Firefox":
            browser_image = "/images/firefox.png";
        break;
        case "Microsoft Edge":
            browser_image = "/images/edge.png";
        break;
        case "Opera":
            browser_image = "/images/opera.png";
        break;
        case "Safari":
            browser_image = "/images/safari.png";
        break;
    }

    return {
        browser: browser_image,
        os: os_image
    };
}
export default {
    ua,
    get_image,
}