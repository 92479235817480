import Swal from 'sweetalert2'
export default {
    success: ({title, message})=>Swal.fire(
        title||'OK',
        message,
        'success'
    ),
    error: ({title, message})=>Swal.fire(
        title||'Oops...',
        message,
        'error'
    ),
    confirm: ({title, message})=>Swal.fire({
        title: title,
        text:message,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Yes',
        denyButtonText: `No`,
    }).then((result) => {
        if (result.isConfirmed) {
            return true;
        } else if (result.isDenied) {
            return false;
        }
    })
}