<template>
    <v-dialog
        v-model="dialog"
        width="800px"
        persistent
    >
        <v-card>
            <v-card-title>
                Update XAPK for {{ version }}
            </v-card-title>
            <v-card-text>
                <v-text-field
                    v-model="xapk"
                    readonly
                    v-if="xapk"
                >
                    <template v-slot:append-outer>
                        <v-btn @click="doRepick" v-if="xapk" :disabled="Overlay" :loading="Overlay" color="error">Repick</v-btn>
                    </template>
                </v-text-field>
                <FM @onPick="doPick" root="/xapk" v-else/>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="close" v-if="!Overlay">Cancel</v-btn>
                <v-btn @click="doUpdate" :disabled="!xapk || Overlay" :loading="Overlay" color="primary">Update</v-btn>
                <v-btn @click="doRemove" v-if="xapk && current_xapk" :disabled="Overlay" :loading="Overlay" color="error">Remove & Update</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import FM from '../views/FileServe.vue';
export default {
    components: {
        FM,
    },
    props: {
        Overlay: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            version: '',
            dialog: false,
            xapk: '',
            current_xapk: '',
        }
    },
    methods: {
        doPick(f) {
            if(f && f.file_name){
                this.xapk = f.file_name;
            }
            this.$emit('onPick', this.xapk);
        },
        open(version, xapk) {
            if(xapk){
                this.xapk = xapk;
                this.current_xapk = xapk;
            }
            this.version = version;
            this.dialog = true;
        },
        close() {
            this.version = '';
            this.xapk = '';
            this.dialog = false;
        },
        doRepick(){
            this.xapk = '';
            this.current_xapk = '';
        },
        doUpdate() {
            this.$emit('onUpdate', this.xapk);
            this.close();
        },
        doRemove() {
            this.current_xapk = '';
            this.$emit('onUpdate', '');
            this.close();
        }
    }
}
</script>